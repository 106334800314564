
import { computed, defineComponent, PropType } from "vue";
import TooltipButton from "@/components/TooltipButton.vue";
import { useActor } from "@xstate/vue";
import { DataMachineActor } from "@/machines/dataMachine";
import { BookState } from "@/machines/bookMachine";

export default defineComponent({
  props: {
    buttonsRef: {
      type: Object as PropType<DataMachineActor[]>,
      required: true,
    },
    bookState: {
      type: Object as PropType<BookState>,
      required: true,
    },
  },
  setup(props) {
    const btn = computed(function btnState() {
      const buttons = props.buttonsRef.map((ref) => useActor(ref));
      const [createState, removeState, finishState, unfinishState] =
        buttons.map((btn) => ({
          isLoading: computed(() => btn.state.value.matches("loading")),
          isError: computed(() => btn.state.value.matches("failure")),
          error: computed(() => btn.state.value.context?.message),
        }));

      const [create, remove, finish, unfinish] = buttons.map(
        (btn) => () => btn.send({ type: "CLICK" })
      );
      return {
        btnState: { createState, removeState, finishState, unfinishState },
        evtFun: { create, remove, finish, unfinish },
      };
    });

    // when to show
    // const showBtns = computed(() => props.bookState.matches("success"));
    const showAddBtn = computed(() =>
      props.bookState.matches("success.unread")
    );
    const showRemoveBtn = computed(() =>
      props.bookState.matches("success.read")
    );
    const showFinBtn = computed(() =>
      props.bookState.matches("success.read.duration.unfinish")
    );
    const showUnfinBtn = computed(() =>
      props.bookState.matches("success.read.duration.finish")
    );
    return {
      showAddBtn,
      showRemoveBtn,
      showFinBtn,
      showUnfinBtn,
      btn,
    };
  },
  components: {
    TooltipButton,
  },
});
