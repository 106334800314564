
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: String,
    clickHandler: { type: Function, required: true },
    icon: { type: String, required: true },
    state: { type: Object, required: true },
  },
  setup(props) {
    return {
      isLoading: props.state.isLoading,
      isError: props.state.isError,
      error: props.state.error,
    };
  },
});
